<template>
  <div class="fill-height overflow-y-auto">
    <div class="hidden-sm-and-up">
      <v-img src="../assets/img/background_login.png" width="100%"></v-img>
      <v-img id="logo" src="../assets/img/bmms_logo.png" max-width="95"></v-img>
    </div>

    <v-container
      class="bg d-flex justify-center"
      :class="$vuetify.breakpoint.smAndUp ? 'fill-height' : ''"
      fluid
    >
      <v-card color="transparent" class="elevation-0" width="800">
        <v-row class="fill-height ma-0">
          <v-col cols="12" sm="6" class="login-card my-0 py-0">
            <div class="d-flex align-center fill-height">
              <v-card-text
                class="text-center ma-0 pa-0"
                :class="
                  $vuetify.breakpoint.smAndUp ? 'white--text' : 'primary--text'
                "
              >
                <div class="pa-4">
                  <v-img
                    src="../assets/img/bmms_logo.png"
                    max-width="195"
                    class="mx-auto d-none d-sm-flex"
                  ></v-img>
                  <div>
                    <h1 class="text-h3 font-weight-bold mt-2">M-BMMS</h1>
                    <div>
                      Motorway - Bridge Maintenance Management System
                      <br />ระบบบริหารทรัพย์สินโครงสร้างพื้นฐานงานสะพาน
                    </div>
                  </div>
                </div>
              </v-card-text>
            </div>
          </v-col>

          <v-col cols="12" sm="6" class="white">
            <v-card-text
              v-if="page === 'login'"
              :class="$vuetify.breakpoint.smAndUp ? 'py-16' : ''"
            >
              <v-alert
                v-model="isAlert"
                color="primary"
                dismissible
                type="warning"
                dense
                text
              >
                เข้าสู่ระบบไม่สำเร็จ
              </v-alert>
              <v-form>
                <v-text-field
                  v-model.trim="username"
                  placeholder="ชื่อผู้ใช้"
                  name="username"
                  type="text"
                  required
                  prepend-inner-icon="mdi-account-circle"
                  color="primary"
                  @keyup.enter="doLogin()"
                />
                <v-text-field
                  v-model.trim="password"
                  id="password"
                  placeholder="รหัสผ่าน"
                  name="password"
                  type="password"
                  prepend-inner-icon="mdi-key"
                  color="primary"
                  @keyup.enter="doLogin()"
                />
                <div class="d-flex justify-end">
                  <div class="pointer-hover" @click="page = 'register'">
                    ลืมรหัสผ่าน ?
                  </div>
                </div>
                <v-btn
                  color="primary"
                  class="white--text mt-4"
                  block
                  @click="doLogin()"
                >
                  {{ buttonText }}
                  <v-progress-circular
                    v-if="isLoading"
                    color="grey lighten-2"
                    :size="20"
                    indeterminate
                  ></v-progress-circular>
                </v-btn>
              </v-form>
            </v-card-text>
            <v-card-text v-else>
              <ModalForgot @back="page = 'login'" />
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <div
      class="grey--text caption text-right"
      style="position: absolute; bottom: 0; right: 4px"
    >
      Mode: {{ mode }}
      <br />
      Version: {{ appVersion }}
    </div>
  </div>
</template>

<script>
import ModalForgot from '../components/login/modalForgot.vue'
export default {
  components: {
    ModalForgot
  },
  data () {
    return {
      username: '',
      password: '',
      buttonText: 'เข้าสู่ระบบ',
      isAlert: false,
      isLoading: false,
      appVersion: process.env.VUE_APP_VERSION,
      mode: process.env.NODE_ENV,
      page: 'login'
    }
  },
  computed: {
    textRules () {
      return [(v) => !!v || 'Field is required']
    }
  },
  methods: {
    async doLogin () {
      if (this.username && this.password) {
        this.buttonText = 'กำลังเข้าสู่ระบบ'
        this.isLoading = true
        const payload = {
          username: this.username,
          password: this.password
        }
        const isSuccess = await this.$store.dispatch('user/doLogin', payload)
        if (isSuccess) {
          this.isOpenDialog = false
          this.isFetching = false
          this.isLoading = false
          this.buttonText = 'เข้าสู่ระบบ'
          this.username = ''
          this.password = ''
          // this.$emit('successQuery', true)
          // this.saveDeviceToBackend()
          this.$router.go({
            path: '/'
          })
          return true
        } else {
          this.loginFail = true
          this.isAlert = true
          this.buttonText = 'เข้าสู่ระบบ'
          this.isFetching = false
          this.isLoading = false
          return false
        }
      } else {
        this.isAlert = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 600px) {
  .bg {
    background: url("../assets/img/background_login.png");
    background-position: center center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }
  .login-card {
    background-color: rgba(99, 0, 17, 0.85);
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#logo {
  display: block;
  margin-top: -47.5px;
  margin-left: auto;
  margin-right: auto;
}
</style>
