<template>
  <div>
    <div class="">
        <v-icon @click="$emit('back')">mdi-arrow-left</v-icon>
      </div>
    <div class="d-flex justify-center back-arrow">
      <h2 class="primary--text text-center">ลืมรหัสผ่าน</h2>
      <div>
      </div>
    </div>
    <v-form ref="forgotForm">
      <v-text-field
        placeholder="อีเมล"
        name="username"
        type="text"
        v-model="userEmail"
        required
        :rules="[validateEmail]"
        prepend-inner-icon="mdi-email"
        color="primary"
        @focusout="hasBeenRequest = false"
      />
    </v-form>
    <div class="mt-2">
      <v-btn color="primary" :elevation="0" block @click="requestNewPassword"> ส่งอีเมลแจ้งเตือน </v-btn>
    </div>
    <template v-if="hasBeenRequest">
      <div v-if="requestSuccess" class="mt-2">
        Reset รหัสผ่านสำเร็จ
      </div>
      <div v-else class="mt-2">
        Reset รหัสผ่านไม่สำเร็จ
      </div>
    </template>
  </div>
</template>
<script>
import Utils from '@/assets/js/utils'

export default {
  name: 'ForgotComponent',
  data: () => ({
    validateEmail: Utils.validateEmail,
    userEmail: '',
    hasBeenRequest: false,
    requestSuccess: false
  }),
  methods: {
    async requestNewPassword (e) {
      if (!this.$refs.forgotForm.validate()) { return }
      const success = await this.$store.dispatch('user/requestNewPassword', { email: this.userEmail })
      this.hasBeenRequest = true
      this.requestSuccess = success
    }
  }
}
</script>

<style scoped>
.back-arrow{
  position:relative;
  top:0;
  left: 0;
}
</style>
